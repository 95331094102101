import axios from 'axios';

class chatApi {
    async getNewMessages(input) {
      try {
        var response = await axios.post('https://chatapi.visbok.se/chatapi/messages/getCompaniesNewMessages', input);
        if (response.status === 200) {
          return response.data;
        }
      } catch (error) {
        console.log("Error", error.message);
      }
    }
}

var apiToCall = new chatApi();

export default apiToCall;