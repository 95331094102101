import React from 'react';

export default function AdminMessages(props) {
    return (
        <div className="outgoing_msg">
            <div className="sent_msg">
                <p>{props.message.userMessage}</p>
                <span className="time_date">{props.message.time}</span>
            </div>
        </div>
    )
}
