import Cookies from 'universal-cookie';
import * as ActionTypes from "./ActionTypes";
import axios from "axios";

const cookies = new Cookies();

export const getMessage = () => dispatch => {
    let token = cookies.get('auth-token');
    dispatch(chatRequestLoading(true));
    axios.get(`https://chatapi.visbok.se/chatapi/messages/`, {
    // axios.get(`http://test2.visbok.se/chatapi/messages/`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    })
        .then(res => {
            dispatch(chatRequestSuccess(res.data));
        })
        .catch(error => dispatch(chatRequestFailed(error.message)));
};
export const chatRequestLoading = () => ({
    type: ActionTypes.MESSAGE_REQUEST_LOADING
});
export const chatRequestFailed = errMess => ({
    type: ActionTypes.MESSAGE_REQUEST_FAILED,
    payload: errMess
});
export const chatRequestSuccess = response => ({
    type: ActionTypes.MESSAGE_REQUEST_SUCCESS,
    payload: response
});

export const sendMessage = (message) => dispatch => {
    let token = cookies.get('auth-token');
    //dispatch(chatRequestLoading(true));
    axios.post('https://chatapi.visbok.se/chatapi/admin/messages', message, {
    // axios.post('http://test2.visbok.se/chatapi/admin/messages', message, {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    })
    .then(res => {
        console.log(res.data)
        //dispatch(chatRequestSuccess(res.data));
    })
    .catch(error => console.log("Error", error.message));
};