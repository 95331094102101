import * as ActionTypes from '../Actions/ActionTypes';

export const ChatReducer = (state = {
    isLoading: true,
    errmsg: null,
    messages: []
}, action) => {
    switch(action.type){
        case ActionTypes.MESSAGE_REQUEST_LOADING:
            return {...state, isLoading: true, errmsg: null, messages: []};
        case ActionTypes.MESSAGE_REQUEST_SUCCESS:
            return {...state, isLoading: false, errmsg: null, messages: action.payload};
        case ActionTypes.MESSAGE_REQUEST_FAILED:
            return {...state, isLoading:false, errmsg: action.payload, messages: []};
        default:
            return state;
    }
}