import React from 'react';
import { Main, Chat } from './Components';
import { Switch, Route } from "react-router-dom";
import PrivateRoute from './PrivateRoute';

function App() {
  return (
    <Switch>
        <Route exact path='/' component={Main} />
        <PrivateRoute path='/home' component={Chat} />
      </Switch>
  );
}

export default App;