import * as ActionTypes from '../Actions/ActionTypes';

export const LoginReducer = (state = {
    isLoading: true,
    errmsg: null,
    loginResponse: {}
}, action) => {
    switch(action.type){
        case ActionTypes.LOGIN_REQUEST_LOADING:
            return {...state, isLoading: true, errmsg: null, loginResponse: {}};
        case ActionTypes.LOGIN_REQUEST_SUCCESS:
            return {...state, isLoading: false, errmsg: null, loginResponse: action.payload};
        case ActionTypes.LOGIN_REQUEST_FAILED:
            return {...state, isLoading:false, errmsg: action.payload, loginResponse: {}};
        default:
            return state;
    }
}