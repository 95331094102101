import React from 'react';
import { Login, Chat } from '../Components';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function MainPage() {
    let isAuthenticate = cookies.get('auth-token');
    return (
        isAuthenticate === undefined ? <Login /> : <Chat />
    )
};