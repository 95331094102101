import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { sendMessage, getMessage } from '../../Actions/ChatActions';
import MessageBox from './MessageBox';
import Sidebar from './Sidebar';
import chatApi from '../../Api/ChatApi';
import * as _ from 'lodash';
import './style.css';
// import socketIOClient from "socket.io-client";

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminMessage: '',
      selectedCompanyName: '',
      selectedCompanyId: '',
      messagesList: [],
      selectedMessages: [],
      newMessageNotifier: [],
    }
  }
  componentDidMount() {
    setInterval(async () => {
      let inputArray = [];
      if (this.state.messagesList.length > 0) {
        inputArray = this.state.messagesList.map(message => {
          let newObject = { id: message._id, time: message.doc[message.doc.length - 1].createdAt };
          return newObject;
        });
      }
      let newMessages = await chatApi.getNewMessages(inputArray);
      if (newMessages && newMessages.length > 0) {
        this.handleMessagesChange(newMessages);
      }
    }, 30000);
  }
  render() {
    return (
      <div className="row">
        <div className="col-md-4 active_chat" style={{ height: window.innerHeight, }}>
          {
            this.state.messagesList && this.state.messagesList.map((company, index) => {
              return (
                <Sidebar key={index} company={company} setCompanyName={this.setCompanyName} newMessageNotifier={this.state.newMessageNotifier} isInNewMessageList={this.isInNewMessageList} removeFromNewMessages={this.removeFromNewMessages} />
              )
            })
          }
        </div>
        <div className="col-md-8 pl-3 pr-3">
          <MessageBox selectedMessage={this.state.selectedMessages} adminMessage={this.state.adminMessage} handleAdminMessageChange={this.handleAdminMessageChange} selectedCompanyName={this.state.selectedCompanyName} handleSendMessage={this.handleSendMessage} />
        </div>
      </div>
    )
  }
  removeFromNewMessages = (id) => {
    let stateArray = [...this.state.newMessageNotifier];
    let updatedArray = _.filter(stateArray, obj => { return obj.id !== id });
    console.log("Updated Array", updatedArray);
    this.setState({ newMessageNotifier: updatedArray });
  }
  isInNewMessageList = (id) => {
    let returnValue = false;
    let isIncluded = _.find(this.state.newMessageNotifier, obj => { return obj.id === id });
    console.log("isIncluded", isIncluded);
    if (isIncluded) returnValue = true;
    return returnValue;
  }
  handleMessagesChange = (messages) => {
    if (this.state.messagesList && this.state.messagesList.length === 0) {
      this.setState({ messagesList: messages });
    } else {
      let cloneState = _.cloneDeep(this.state.messagesList);
      let cloneNotifier = _.cloneDeep(this.state.newMessageNotifier);
      messages.forEach(comingMessage => {
        let isInState = _.find(cloneState, obj => { return obj._id === comingMessage._id });
        console.log("isInState", isInState);
        if (isInState) {
          isInState.doc = [...isInState.doc, ...comingMessage.doc];
          let countObj = { id: comingMessage._id, count: comingMessage.doc.length }
          cloneNotifier.push(countObj);
          // console.log("comingMessage.isRequest", comingMessage.isRequest);
          // if(comingMessage.isRequest){
          //   let countObj = { id: comingMessage._id, count: comingMessage.doc.length }
          //   cloneNotifier.push(countObj);
          // }
        } else {
          cloneState.push(comingMessage);
          let countObj = { id: comingMessage._id, count: comingMessage.doc.length }
          cloneNotifier.push(countObj);
        }
      });
      this.setState({ messagesList: cloneState, newMessageNotifier: cloneNotifier });
    }
  }
  setCompanyName = (companyName, companyId) => {
    let selectedMessage = _.filter(this.state.messagesList, obj => { return obj._id === companyId });
    this.setState({ selectedMessages: selectedMessage, selectedCompanyName: companyName, selectedCompanyId: companyId })
  }
  handleAdminMessageChange = (e) => {
    this.setState({ adminMessage: e.target.value });
  }
  handleSendMessage = (e) => {
    //let newMessages = [...this.state.selectedMessages];
    this.setState({ adminMessage: "" });
    let newObj = {
      companyId: this.state.selectedCompanyId,
      companyName: this.state.selectedCompanyName,
      userMessage: this.state.adminMessage,
      isRequest: false,
    }
    // newMessages[0].doc.push(newObj);
    // this.setState({ selectedMessages: newMessages });

    this.props.sendMessage(newObj);
  }
}
const mapDispatchToProps = dispatch => ({
  sendMessage: (message) => dispatch(sendMessage(message)),
  getMessage: () =>  dispatch(getMessage()),
});
export default connect(null, mapDispatchToProps)(Chat);