import React from 'react';
import CompanyMessages from './CompanyMessages';
import AdminMessages from './AdminMessages';

export default function MessageBox(props) {
    return (
        <>
            <div className="msg_history" style={{ height : window.innerHeight }}>
                {
                    props.selectedMessage && props.selectedMessage.map((companyData, index) => {
                        return (
                            <React.Fragment key={index}>
                                {
                                    companyData.doc.map((message, index) => {
                                        if (message.isRequest) {
                                            return (
                                                <CompanyMessages key={index} message={message} />
                                            )
                                        } else {
                                            return (
                                                <AdminMessages key={index} message={message} />
                                            )
                                        }
                                    })
                                }
                            </React.Fragment>
                        )
                    })
                }
            </div>
            {
                props.selectedCompanyName !== null &&
                <div className="d-flex message-input">
                    <input type="text" className="form-control" placeholder="Type a message" value={props.adminMessage} onChange={(e) => props.handleAdminMessageChange(e)} required />
                    <button className="msg_send_btn" type="button" onClick={(e) => props.handleSendMessage(e)}>
                        <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
                    </button>
                </div>
            }
        </>
    )
}
