import React from 'react';
import * as _ from 'lodash';

export default function Sidebar(props) {
	let newMessageCount = _.find(props.newMessageNotifier, obj => { return obj.id === props.company._id })
	return (
		<div className="chat_list active_chat" onClick={() => handleClick(props.company.doc[0].companyName, props.company._id)}>
			<div className="chat_people">
				<div className="chat_img">
					<img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" />
				</div>
				<div className="chat_ib">
					<h5>
						{props.company.doc[0].companyName}
						<span className="chat_date">{props.company.doc[0].time.substr(0, 10)}</span><br/>
						{
							props.isInNewMessageList(props.company._id) && 
							<span className="badge badge-primary">{newMessageCount && newMessageCount.count}</span>
						}
					</h5>
					<p>{props.company.doc[props.company.doc.length - 1].userMessage}</p>
				</div>
			</div>
		</div>
	)
	function handleClick(companyName, companyId){
		props.setCompanyName(companyName, companyId);
		props.removeFromNewMessages(companyId);
	}
}