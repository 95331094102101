import React, { useEffect } from 'react';
import Chat from './Chat';
import { useSelector, useDispatch } from 'react-redux';
import { getMessage } from '../../Actions/ChatActions';

export default function ChatContainer() {
    const dispatch = useDispatch();
    const messages = useSelector(state => state.messages.messages);
    // useEffect(() => {
    //     dispatch(getMessage());
    // }, [dispatch]);
    return (
        <Chat />
    )
}
