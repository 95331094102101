import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function PrivateRoute(props) {
    const { component: Component, ...rest } = props;
    const authenticated = cookies.get('auth-token');
    return (
        <Route {...rest}>
            <React.Fragment>
                {authenticated !== undefined ? <Component {...props} /> : <Redirect to={{ pathname: '/'}} />}
            </React.Fragment>
        </Route>
    );
}