import Cookies from 'universal-cookie';
import * as ActionTypes from "./ActionTypes";
import axios from "axios";

const cookies = new Cookies();

export const loginRequest = userData => dispatch => {
  let result;
  dispatch(loginRequestLoading(true));
  axios.post(`https://chatapi.visbok.se/chatapi/auth/login/`, userData)
  // axios.post(`http://test2.visbok.se/chatapi/auth/login/`, userData)
    .then(res => {
      dispatch(loginRequestSuccess(res.data));
      cookies.set('auth-token', res.data, { path: '/' });
      result = res.data
    })
    .catch(error => dispatch(loginRequestFailed(error.message)));
    return result;
};
export const loginRequestLoading = () => ({
  type: ActionTypes.LOGIN_REQUEST_LOADING
});
export const loginRequestFailed = errMess => ({
  type: ActionTypes.LOGIN_REQUEST_FAILED,
  payload: errMess
});
export const loginRequestSuccess = response => ({
  type: ActionTypes.LOGIN_REQUEST_SUCCESS,
  payload: response
});