import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { LoginReducer } from '../Reducers/LoginReducer';
import { ChatReducer } from '../Reducers/ChatReducer';
import thunk from 'redux-thunk';

export const ConfigureStore = () => {
    const store = createStore (
        combineReducers({
            login : LoginReducer,
            messages : ChatReducer
        }),
        compose(
            applyMiddleware(thunk),
            window.devToolsExtension ? window.devToolsExtension() : f => f
        )
    );
    return store;
};